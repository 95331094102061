import {useEffect, useState, useCallback, useRef} from 'react';
import useSWR, {mutate as swrMutate} from 'swr';
import {RPC} from 'shared/api.js';

import {toJSON, fromJSON} from './utils/jsonConverter.ts';
import {handleError} from './effects.js';

function useRPCInternal(key, shouldFetch) {
  return useSWR(
    shouldFetch ? key : null,
    ([method, jsonParams]) =>
      RPC(method, jsonParams ? fromJSON(jsonParams) : {}),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );
}

export function useRPCQuery(method, {skip, params} = {}) {
  const key = [method, params ? toJSON(params) : ''];

  return useRPCInternal(key, !skip);
}

export function useRPCLazyQuery(method) {
  const [key, setKey] = useState(null);

  const swr = useRPCInternal(key, !!key);

  function fetch(params) {
    setKey([method, JSON.stringify(params)]);
  }

  return [fetch, swr];
}

export function useRPCMutate(method) {
  return useCallback(
    async (params) => {
      const response = await RPC(method, params);

      return swrMutate([method, JSON.stringify(params)], response);
    },
    [method],
  );
}

export function useResource(method, id) {
  const {data} = useSWR(id ? [id] : null, (id) =>
    RPC(method, {filter: {id: [id]}}),
  );

  const resource = data?.rows[0] || {};

  return [resource];
}

export function useGetOneResource(method, id, params = {}) {
  const {data, error} = useSWR(id ? [id, params] : null, (id) =>
    RPC(method, {id, ...params}),
  );

  useEffect(() => {
    if (!error) return;
    handleError(error);
    swrMutate([id], null, false);
  }, [error, id, params]);

  const resource = data || {};

  return [resource];
}

function useFetchRefresh({
  method,
  search_query,
  per_page,
  refresh,
  sort_column,
  sort_direction,
}) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  // Reset pagination state when search query changes
  useEffect(() => {
    setOffset(0);
    setTotal(0);
  }, [search_query]);

  const memo_fetcher = useCallback(() => {
    setLoading(true);
    setResult();

    RPC(method, {
      range: [offset, per_page],
      filter: {
        q: search_query,
      },
      sort: [sort_column, sort_direction],
    })
      .then((result) => {
        setTotal(result.total);
        setResult(result);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [
    method,
    search_query,
    offset,
    per_page,
    sort_column,
    sort_direction,
    setTotal,
  ]);

  useEffect(() => {
    memo_fetcher();
  }, [memo_fetcher, refresh]);

  return {
    loading,
    rows: result?.rows || [],
    total,
    offset,
    setOffset,
  };
}

export function useFetchTableData({
  default_sort_column = 'created_at',
  default_sort_direction = 'DESC',
  method,
  refresh,
}) {
  const entries_per_page = 10;
  const [search_query, setSearchQuery] = useState('');
  const [sort_column, setSortColumn] = useState(default_sort_column);
  const [sort_direction, setSortDirection] = useState(default_sort_direction);

  const {loading, rows, total, offset, setOffset} = useFetchRefresh({
    method,
    search_query,
    per_page: entries_per_page,
    refresh,
    sort_column,
    sort_direction,
  });

  function resetTable() {
    setSortColumn(default_sort_column);
    setSortDirection(default_sort_direction);
    setOffset(0);
  }

  function orderBy(field) {
    if (sort_column === field) {
      sort_direction === 'DESC'
        ? setSortDirection('ASC')
        : setSortDirection('DESC');
    } else {
      setSortColumn(field);
      setSortDirection('ASC');
    }
  }

  return {
    orderBy,
    resetTable,
    search_query,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  };
}

/**
 * Custom hook to run a side effect with the most up-to-date callback.
 *
 * This hook is more relevant for handling callbacks than directly using `useEffect`,
 * as it ensures the callback is always up-to-date and avoids issues with stale closures in dependencies.
 */
export function useSideEffect(callback, values) {
  const callbackRef = useRef();

  callbackRef.current = callback;

  useEffect(() => {
    callbackRef.current && callbackRef.current(...values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, values);
}
