import React, {forwardRef} from 'react';
import classNames from 'classnames/bind.js';
import {_} from 'shared/l10n.js';

import text_styles from '../styles/text_styles.module.scss';

import styles from './Select.module.scss';

function Select(
  {
    className,
    label,
    options,
    error = '',
    readOnly,
    truncate_length = 29,
    withDefaultValue = true,
    withBottomMargin = false,
    ...props
  },
  ref,
) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.bottomMargin]: withBottomMargin,
      })}>
      {label && (
        <label className={classNames(text_styles.caption_left, styles.label)}>
          {label}
        </label>
      )}
      <div className={classNames(styles.wrapper, className)}>
        <select
          className={classNames(
            text_styles.body2,
            styles.select,
            readOnly && styles.read_only,
          )}
          defaultValue={withDefaultValue ? '' : undefined}
          ref={ref}
          disabled={readOnly}
          {...props}>
          <option disabled value="">
            {_('auswählen')}
          </option>
          {options.map((item) => (
            <option value={item.id} key={item.id}>
              {trucate(item.name, truncate_length)}
            </option>
          ))}
        </select>
        {!readOnly && (
          <p className={classNames(text_styles.caption_left, styles.error)}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
}

// https://github.com/get-momo/issues/issues/3247
export function trucate(str, truncate_length = 29) {
  if (truncate_length === 0) return str;

  return str.length < truncate_length
    ? str
    : `${str.slice(0, truncate_length - 3)}…`;
}

export default forwardRef(Select);
