import React from 'react';
import {Controller} from 'react-hook-form';

import ManagerReferenceField from '../../../../components/reference_field/ManagerReferenceField.js';

export function SelectManagerReferenceField({name, label, ...props}) {
  return (
    <Controller
      name={name}
      render={({field: {value}}) => (
        <ManagerReferenceField id={value} label={label} {...props} />
      )}
    />
  );
}
