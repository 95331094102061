import {displayBankName, formatDate, formatIban} from 'shared/utils.js';

import Table from '../../components/Table.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';

export default function AccountsTable({
  data,
  onOpen,
  orderBy,
  sort_column,
  sort_direction,
  loading,
}) {
  return (
    <Table
      data={data}
      onOpen={onOpen}
      orderBy={orderBy}
      sort_column={sort_column}
      sort_direction={sort_direction}
      loading={loading}
      columns={columns}
    />
  );
}

const columns = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'accountId',
    text_styles: 'body2',
    sort: 'accountId',
  },
  {
    id: 'accountType',
    Header: 'Type',
    accessor: 'accountType',
    text_styles: 'body2',
    sort: 'accountType',
  },
  {
    id: 'accountIban',
    Header: 'IBAN',
    accessor: ({accountIban}) => formatIban(accountIban),
    text_styles: 'body2',
    sort: 'accountIban',
  },
  {
    id: 'accountBic',
    Header: 'BIC',
    accessor: 'accountBic',
    text_styles: 'body2',
    sort: 'accountBic',
  },
  {
    id: 'managerId',
    Header: 'Manager',
    accessor: 'managerId',
    Cell: ({value}) => <ManagerReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'managerId',
  },
  {
    id: 'accountCreatedAt',
    Header: 'Created at',
    accessor: 'accountCreatedAt',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'accountCreatedAt',
  },
  {
    id: 'accountBank',
    Header: 'Bank',
    accessor: 'accountBank',
    Cell: ({value}) => displayBankName(value),
    text_styles: 'body2',
    sort: 'accountBank',
  },
];
