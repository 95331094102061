import {accountTypes} from './constants.js';

export function getIsCondominiumFeeType(type) {
  return type === accountTypes.CONDOMINIUM_FEE;
}

export function getIsCondominiumReserveType(type) {
  return type === accountTypes.CONDOMINIUM_RESERVE;
}

export function getIsCondominiumType(type) {
  return getIsCondominiumReserveType(type) || getIsCondominiumFeeType(type);
}

export function getIsDeposit(type) {
  return type === accountTypes.CASH_DEPOSIT;
}
