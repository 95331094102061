import Dialog from 'shared-web/components/dialog/Dialog.js';
import IconButton from 'shared-web/components/IconButton.js';
import Button from 'shared-web/components/Button.js';

import {ReactComponent as EditSvg} from '../../../assets/edit.svg';

import {AccountForm} from './AccountForm.js';
import {useAccountDialog} from './hooks/useAccountDialog.js';
import {useAccountForm} from './hooks/useAccountForm.js';

export function AccountDialog({onCreate, onUpdate, onExited, onError}) {
  const {formMode, isEditing, handleEditButtonClick} = useAccountDialog();

  const {
    handleSaveButtonClick,
    form,
    form: {formState},
    isLoading,
  } = useAccountForm({
    formMode,
    onCreate,
    onUpdate,
    onError,
  });

  const isCreateMode = formMode === 'create';
  const isUpdateMode = formMode === 'update';

  return (
    <Dialog
      title={isCreateMode ? 'Create Account' : 'Edit Account'}
      onExited={onExited}
      footer={
        isEditing && (
          <Button
            title="Save"
            loading={formState.isSubmitting || isLoading}
            onClick={handleSaveButtonClick}
            disabled={!formState.isDirty}
          />
        )
      }
      additionalIcons={
        isUpdateMode
          ? [
              !isEditing && (
                <IconButton key="edit" onClick={handleEditButtonClick}>
                  <EditSvg />
                </IconButton>
              ),
            ]
          : []
      }>
      <AccountForm form={form} formMode={formMode} isEditing={isEditing} />
    </Dialog>
  );
}
