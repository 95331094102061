import {CONDOMINIUM_TYPES as condominiumTypes} from 'shared/constants.js';

export const accountTypes = {
  CASH_DEPOSIT: 'CASH_DEPOSIT',
  CONDOMINIUM_FEE: condominiumTypes.CONDOMINIUM_FEE,
  CONDOMINIUM_RESERVE: condominiumTypes.CONDOMINIUM_RESERVE,
};

export const accountTypeOptions = [
  {
    id: accountTypes.CASH_DEPOSIT,
    name: 'Deposit',
  },
  {
    id: accountTypes.CONDOMINIUM_FEE,
    name: 'Condominium Fee',
  },
  {
    id: accountTypes.CONDOMINIUM_RESERVE,
    name: 'Condominium Reserve',
  },
];
