import Select from 'shared-web/components/Select.js';
import {useMemo} from 'react';

import {useRPCQuery} from '../hooks.js';

export default function SelectCondominium({
  label,
  name,
  value,
  onChange,
  readOnly,
  error,
}) {
  const {
    data,
    isLoading,
    error: queryError,
  } = useRPCQuery('getCondominiums', {
    params: {
      range: [0, 100],
      sort: ['createdAt', 'ASC'],
      filter: {},
    },
  });

  const options = useMemo(
    () =>
      data?.rows?.map((condominium) => ({
        id: condominium.id,
        name: condominium.legalName,
      })) || [],
    [data?.rows],
  );

  return (
    <Select
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      readOnly={readOnly || isLoading}
      error={error || queryError?.message}
      withDefaultValue={false}
      withBottomMargin
    />
  );
}
