export const condominiumAccountMapper = {
  create: (formData) => {
    const createData = {
      managerId: formData.managerId,
      type: formData.accountType,
      condominiumId: formData.condominiumId,
      iban: formData.iban,
      bic: formData.bic,
      bank: formData.bank,
      bankExternalReference: formData.bankExternalReference,
      effectiveAnnualInterestRate: formData.effectiveAnnualInterestRate,
    };

    return {createData};
  },

  update: (formData, apiData) => {
    const updateData = {
      formanceId: formData.formanceId || null,
      atlarId: formData.atlarId || null,
    };

    return {
      id: apiData?.id,
      version: formData.version,
      updateData,
    };
  },

  toForm: (apiData) => ({
    id: apiData.id ?? '',
    managerId: apiData.managerId ?? '',
    accountType: apiData.type ?? '',
    condominiumId: apiData.condominiumId ?? '',
    iban: apiData.iban ?? '',
    bic: apiData.bic ?? '',
    bank: apiData.bank ?? '',
    bankExternalReference: apiData.bankExternalReference ?? '',
    effectiveAnnualInterestRate: apiData.effectiveAnnualInterestRate ?? 0,
    formanceId: apiData.formanceId ?? '',
    atlarId: apiData.atlarId ?? '',
    createdAt: apiData.createdAt,
    version: apiData.version ?? null,
  }),
};
