import {useSelector} from 'react-redux';
import {useHistory, Switch, Route} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import {useFetchTableData} from '../../hooks.js';
import {refreshAccountsData} from '../../actions.js';

import styles from './AccountsScreen.module.scss';
import {AccountDialog} from './AccountDialog/index.js';
import AccountsTable from './AccountsTable.js';

export default function AccountsScreen() {
  const {refresh} = useSelector((state) => state.accounts);

  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'getBackofficeAccountProjections', refresh});

  const history = useHistory();

  function routeToListScreen() {
    history.push(BACKOFFICE_PATHS.accounts);
  }

  function handleAccountUpdate() {
    refreshAccountsData();

    history.push(BACKOFFICE_PATHS.accounts);
  }

  function handleAccountCreate() {
    refreshAccountsData();

    history.push(BACKOFFICE_PATHS.accounts);
  }

  function handleAddAccountButtonClick() {
    history.push(`${BACKOFFICE_PATHS.accounts}/new`);
  }

  return (
    <Layout
      button={
        <ButtonAdd
          title="Create Account"
          onClick={handleAddAccountButtonClick}
        />
      }
      search={<SearchInput onValue={setSearchQuery} />}>
      <Switch>
        <Route path={`${BACKOFFICE_PATHS.accounts}/:id/:type?`}>
          <AccountDialog
            onCreate={handleAccountCreate}
            onUpdate={handleAccountUpdate}
            onExited={routeToListScreen}
          />
        </Route>
      </Switch>

      <div className={styles.content}>
        <AccountsTable
          data={rows}
          onOpen={(account) => {
            history.push(
              `${BACKOFFICE_PATHS.accounts}/${account.accountId}/${account.accountType}`,
            );
          }}
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
        />
        {total > 0 && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}
