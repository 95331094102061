import React from 'react';
import {Controller} from 'react-hook-form';

import SelectManager from '../../../../components/SelectManager.js';

export function SelectManagerField({name, label, readOnly, ...props}) {
  return (
    <Controller
      name={name}
      render={({field: {value, onChange}, fieldState}) => (
        <SelectManager
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          error={fieldState.error?.message}
          rpc_method="listNonSurrogateManagers"
          {...props}
        />
      )}
    />
  );
}
