import {useState} from 'react';
import {useParams} from 'react-router-dom';

export function useAccountDialog() {
  const {id} = useParams();
  const formMode = id === 'new' ? 'create' : 'update';
  const [isEditing, setIsEditing] = useState(formMode === 'create');

  function handleEditButtonClick() {
    setIsEditing(true);
  }

  return {
    formMode,
    isEditing,
    handleEditButtonClick,
  };
}
