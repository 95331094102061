export const toJSON = function <T>(value: T): string | undefined {
  try {
    return JSON.stringify(value);
  } catch (err) {
    console.log('JSON stringify error', err);

    return undefined;
  }
};

export const fromJSON = function <T>(json: unknown): T | undefined {
  if (!json || typeof json !== 'string') {
    return undefined;
  }

  try {
    return JSON.parse(json) as T;
  } catch (err) {
    console.log('JSON parse error', err);

    return undefined;
  }
};
