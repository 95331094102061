import {accountTypes} from '../constants.js';

export const depositAccountMapper = {
  create: (formData) => ({
    createData: {
      managerId: formData.managerId,
      iban: formData.iban,
      bic: formData.bic,
      bank: formData.bank,
      bankExternalReference: formData.bankExternalReference,
      effectiveAnnualInterestRate: formData.effectiveAnnualInterestRate,
    },
  }),

  update: (formData, apiData) => {
    const updateData = {
      formanceId: formData.formanceId || null,
      atlarId: formData.atlarId || null,
    };

    return {
      id: apiData?.id,
      updateData,
    };
  },

  toForm: (apiData) => ({
    id: apiData.id ?? '',
    managerId: apiData.managerId ?? '',
    accountType: accountTypes.CASH_DEPOSIT,
    iban: apiData.iban ?? '',
    bic: apiData.bic ?? '',
    bank: apiData.bank ?? '',
    bankExternalReference: apiData.bankExternalReference ?? '',
    effectiveAnnualInterestRate: apiData.effectiveAnnualInterestRate ?? '',
    formanceId: apiData.formanceId ?? '',
    atlarId: apiData.atlarId ?? '',
    totalVibans: apiData.totalVibans ?? '',
    allocatedVibans: apiData.allocatedVibans ?? '',
    createdAt: apiData.createdAt ?? '',
  }),
};
