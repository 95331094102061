import React from 'react';
import {Controller} from 'react-hook-form';

import SelectCondominium from '../../../../components/SelectCondominium.js';

export function SelectCondominiumField({name, label, readOnly, ...props}) {
  return (
    <Controller
      name={name}
      render={({field: {value, onChange}, fieldState}) => (
        <SelectCondominium
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          error={fieldState.error?.message}
          readOnly={readOnly}
          {...props}
        />
      )}
    />
  );
}
