import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useNotify} from 'shared-web/NotifyProvider.js';
import {setFormErrors} from 'shared-web/effects.js';
import {
  BANK_BIC_MAPPING,
  DEFAULT_EFFECTIVE_ANNUAL_INTEREST_RATE,
} from 'shared/constants.js';

import {condominiumAccountMapper} from '../mappers/condominiumAccountMapper.js';
import {depositAccountMapper} from '../mappers/depositAccountMapper.js';
import {defaultFormValues} from '../mappers/defaultFormValues.js';
import {
  getIsCondominiumType,
  getIsDeposit,
  getIsCondominiumFeeType,
} from '../utils.js';
import {useRPCQuery, useRPCMutate, useSideEffect} from '../../../../hooks.js';

export function useAccountForm({formMode, onCreate, onUpdate, onError}) {
  const {id, type: paramAccountType} = useParams();
  const {notify} = useNotify();
  const isCreateMode = formMode === 'create';
  const isUpdateMode = formMode === 'update';

  const form = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  const {handleSubmit, reset, watch, setValue, setError} = form;
  const selectedBank = watch('bank');
  const selectedAccountType = watch('accountType');
  const accountType = paramAccountType || selectedAccountType;
  const isCondominiumType = getIsCondominiumType(accountType);
  const isDepositType = getIsDeposit(accountType);

  const {data: depositAccount} = useRPCQuery('getDepositAccount', {
    params: {
      id,
    },
    skip: isCreateMode || !isDepositType,
  });

  const {data: condominiumAccount} = useRPCQuery('getCondominiumAccount', {
    params: {
      id,
    },
    skip: isCreateMode || !isCondominiumType,
  });

  const createCondominiumAccount = useRPCMutate('createCondominiumAccount');
  const createDepositAccount = useRPCMutate('createDepositAccount');
  const updateCondominiumAccount = useRPCMutate('updateCondominiumAccount');
  const updateDepositAccount = useRPCMutate('updateDepositAccount');

  const account = depositAccount || condominiumAccount;

  useSideEffect(
    (depositAccount) => {
      if (!depositAccount) {
        return;
      }

      const formData = depositAccountMapper.toForm(depositAccount);

      reset(formData);
    },
    [depositAccount],
  );

  useSideEffect(
    (condominiumAccount) => {
      if (!condominiumAccount) {
        return;
      }

      const formData = condominiumAccountMapper.toForm(condominiumAccount);

      reset(formData);
    },
    [condominiumAccount],
  );

  useSideEffect(
    (selectedBank) => {
      const bic = BANK_BIC_MAPPING[selectedBank];

      if (bic) {
        setValue('bic', bic, {shouldDirty: true});
      }
    },
    [selectedBank],
  );

  useSideEffect(
    (selectedAccountType) => {
      if (getIsCondominiumFeeType(selectedAccountType)) {
        setValue('effectiveAnnualInterestRate', 0);
      } else {
        setValue(
          'effectiveAnnualInterestRate',
          DEFAULT_EFFECTIVE_ANNUAL_INTEREST_RATE,
        );
      }
    },
    [selectedAccountType],
  );

  const handleSaveButtonClick = handleSubmit(async (fields) => {
    try {
      if (isCreateMode) {
        const createData = isCondominiumType
          ? condominiumAccountMapper.create(fields)
          : depositAccountMapper.create(fields);

        const response = isCondominiumType
          ? await createCondominiumAccount(createData)
          : await createDepositAccount(createData);

        onCreate?.(response);

        notify({text: 'Account was created.'});
      } else if (isUpdateMode) {
        const updateData = isCondominiumType
          ? condominiumAccountMapper.update(fields, account)
          : depositAccountMapper.update(fields, account);

        const response = isCondominiumType
          ? await updateCondominiumAccount(updateData)
          : await updateDepositAccount(updateData);

        onUpdate?.(response);

        notify({text: 'Account was updated.'});
      }
    } catch (err) {
      if (Array.isArray(err.data)) {
        setFormErrors({
          setError,
          errors: err.data,
          pathFormatter: (str) =>
            str.replace('/createData', '').replace('/updateData', ''),
        });
      }
      const {code} = err;

      const text = 'Error saving account';

      notify({text: code ? `${text}: ${code}` : text});

      onError?.(err);
    }
  });

  return {
    handleSaveButtonClick,
    form,
  };
}
