import React from 'react';
import {Controller} from 'react-hook-form';
import Input from 'shared-web/components/Input.js';

export function InputField({
  name,
  label,
  readOnly,
  formatValue,
  parseValue,
  ...props
}) {
  return (
    <Controller
      name={name}
      render={({field: {value, onChange}, fieldState}) => (
        <Input
          label={label}
          name={name}
          value={formatValue ? formatValue(value) : value}
          onChange={(e) => {
            const newValue = e.target.value;
            onChange(parseValue ? parseValue(newValue) : newValue);
          }}
          error={fieldState.error?.message}
          readOnly={readOnly}
          {...props}
        />
      )}
    />
  );
}
