import React from 'react';
import {Controller} from 'react-hook-form';
import Select from 'shared-web/components/Select.js';

export function SelectField({
  name,
  label,
  options,
  readOnly,
  withDefaultValue = false,
  withBottomMargin = true,
  ...props
}) {
  return (
    <Controller
      name={name}
      render={({field: {value, onChange}, fieldState}) => (
        <Select
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          error={fieldState.error?.message}
          readOnly={readOnly}
          withDefaultValue={withDefaultValue}
          withBottomMargin={withBottomMargin}
          {...props}
        />
      )}
    />
  );
}
