import React from 'react';
import {FormProvider} from 'react-hook-form';
import {formatDate} from 'shared/utils.js';
import {bank_select_options as bankSelectOptions} from 'shared/constants.js';

import {accountTypeOptions} from './constants.js';
import {
  getIsDeposit,
  getIsCondominiumType,
  getIsCondominiumReserveType,
} from './utils.js';
import {
  InputField,
  SelectField,
  SelectManagerField,
  SelectManagerReferenceField,
  SelectCondominiumField,
} from './fields/index.js';

export function AccountForm({form, formMode, isEditing}) {
  const {watch} = form;
  const selectedAccountType = watch('accountType');
  const isCreateMode = formMode === 'create';
  const isUpdateMode = formMode === 'update';
  const isDepositType = getIsDeposit(selectedAccountType);
  const isCondominiumReserveType =
    getIsCondominiumReserveType(selectedAccountType);
  const isCondominiumType = getIsCondominiumType(selectedAccountType);
  const isEffectiveAnnualInterestRateEditable =
    isCreateMode && (isCondominiumReserveType || isDepositType);
  const isAtlarIdEditable = isUpdateMode && isEditing;
  const isFormanceIdEditable = isUpdateMode && isEditing;

  return (
    <FormProvider {...form}>
      {isUpdateMode && <InputField name="id" label="ID" readOnly />}
      <SelectField
        name="accountType"
        label="Account Type"
        options={accountTypeOptions}
        readOnly={!isCreateMode}
        withDefaultValue={false}
        withBottomMargin={true}
      />
      {selectedAccountType && (
        <>
          {isCreateMode ? (
            <SelectManagerField name="managerId" label="Manager" />
          ) : (
            <SelectManagerReferenceField name="managerId" label="Manager" />
          )}
          {isCondominiumType && (
            <SelectCondominiumField
              name="condominiumId"
              label="Condominium"
              readOnly={!isCreateMode}
            />
          )}
          <InputField name="iban" label="IBAN" readOnly={!isCreateMode} />
          <SelectField
            name="bank"
            label="Bank"
            options={bankSelectOptions}
            readOnly={!isCreateMode}
            withDefaultValue={false}
            withBottomMargin={true}
          />
          <InputField name="bic" label="BIC" readOnly={!isCreateMode} />
          <InputField
            name="bankExternalReference"
            label="Bank External Reference"
            readOnly={!isCreateMode}
          />
          <InputField
            name="effectiveAnnualInterestRate"
            label="Effective Annual Interest Rate"
            readOnly={!isEffectiveAnnualInterestRateEditable}
          />
          {isUpdateMode && (
            <>
              <InputField
                name="formanceId"
                label="Formance Account ID"
                readOnly={!isFormanceIdEditable}
              />
              <InputField
                name="atlarId"
                label="Atlar Account ID"
                readOnly={!isAtlarIdEditable}
              />
              {isCondominiumType && (
                <InputField
                  name="updatedAt"
                  label="Updated At"
                  readOnly
                  formatValue={formatDate}
                />
              )}
              <InputField
                name="createdAt"
                label="Created At"
                readOnly
                formatValue={formatDate}
              />
              {isDepositType && (
                <>
                  <InputField
                    name="totalVibans"
                    label="Total VIBANs"
                    readOnly
                  />
                  <InputField
                    name="allocatedVibans"
                    label="Allocated VIBANs"
                    readOnly
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </FormProvider>
  );
}
