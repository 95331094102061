export const defaultFormValues = {
  id: '',
  accountType: '',
  managerId: '',
  condominiumId: '',
  bank: '',
  iban: '',
  bic: '',
  bankExternalReference: '',
  effectiveAnnualInterestRate: 0,
  formanceId: '',
  atlarId: '',
  createdAt: null,
  version: null,
  totalVibans: 0,
  allocatedVibans: 0,
};
